<template>
  <div class="whiteList">
    <div class="mainBox">
      <el-card shadow="hover" class="mb10">
        <el-form v-model="filterForm" size="small" label-width="80px">
          <el-row
            :gutter="10"
            type="flex"
            justify="start"
            style="flex-wrap: wrap; flex-direction: row"
          >
            <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
              <el-form-item label="offerIds:" class="mb5">
                <el-input
                  clearable
                  v-model="filterForm.offerIds"
                  placeholder="offerIds: eg. 2206264 or 2206264,1567396"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
              <el-form-item label="source(s):" class="mb5">
                <group-select
                  v-model="filterForm.sourceIdList"
                  :data-source="sourcesList"
                  :multiple="true"
                  :loading="sourceLoading"
                  reserve-keyword
                  clearable
                  filterable
                  collapse-tags
                  placeholder="select Source"
                  class="w100"
                />
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4">
              <el-form-item label="Affiliate(s):" class="mb5">
                <group-select
                  v-model="filterForm.affiliateIds"
                  :data-source="affiliatesGroupList"
                  class="w100"
                  multiple
                  :loading="affiliateLoading"
                  clearable
                  reserve-keyword
                  filterable
                  collapse-tags
                  placeholder="Please select Affiliate"
                />
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="4" :md="4" :lg="4" :xl="4">
              <el-form-item label="prod:" class="mb5">
                <!--                <el-select v-model="filterForm.prods" clearable multiple filterable>
                  <el-option
                    v-for="item in prodList"
                    :key="item"
                    :value="item"
                    :label="item"
                  ></el-option>
                </el-select>-->
                <el-input
                  clearable
                  v-model="filterForm.prods"
                  placeholder="prods: eg. pord1,pord2"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="4" :md="4" :lg="4" :xl="4">
              <el-form-item label="pid:" class="mb5">
                <el-select v-model="filterForm.pids" clearable multiple filterable>
                  <el-option
                    v-for="item in pidList"
                    :key="item"
                    :value="item"
                    :label="item"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="4" :md="4" :lg="4" :xl="4">
              <el-form-item label="BD Group:" class="mb5">
                <el-select v-model="filterForm.businessType" clearable filterable>
                  <el-option
                    v-for="item in businessTypes"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="2" :md="2" :lg="2" :xl="2">
              <el-form-item class="mb5" label-width="10px">
                <el-checkbox v-model="filterForm.showOffer" @change="getList(1)"
                  >show Offer</el-checkbox
                >
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="4" :md="4" :lg="4" :xl="4">
              <el-form-item label="cpi:" class="mb5">
                <el-select v-model="filterForm.cpi" clearable filterable>
                  <el-option
                    v-for="item in cpis"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="5" :md="5" :lg="5" :xl="5">
              <el-form-item label="status" class="mb5">
                <el-select v-model="filterForm.status" multiple clearable style="width: auto">
                  <el-option
                    v-for="item in statusList"
                    :key="item.value"
                    :value="item.value"
                    :label="item.label"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="7" :md="7" :lg="7" :xl="7">
              <el-form-item label="period:" class="mb5">
                <el-date-picker
                  v-model="filterForm.dateRange"
                  clearable
                  type="monthrange"
                  value-format="yyyy-MM"
                  range-separator="to"
                  start-placeholder="start"
                  end-placeholder="end"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="mt5">
            <el-col :xs="11" :sm="11" :md="11" :lg="11" :xl="11" align="left" class="mr10">
              <el-form-item label-width="0" class="mb5">
                <span :xs="4" :sm="3" :md="3" :lg="4" :xl="3" class="ml10 font-16 cor337ab7"
                  >offer count:[{{ sumInfo.offerCount }}]</span
                >
                <span :xs="4" :sm="3" :md="3" :lg="3" :xl="3" class="ml10 font-16 cor337ab7"
                  >offer confirm: [{{ sumInfo.offerConfirmCount }}]</span
                >
                <span class="progress-label ml10 font-16 cor337ab7"
                  >offer(%):
                  <el-progress
                    :percentage="sumInfo.offerConfirmRate"
                    status="success"
                    :text-inside="true"
                    :stroke-width="20"
                  >
                  </el-progress
                ></span>
              </el-form-item>
              <el-form-item label-width="0" class="mb5">
                <span :xs="4" :sm="3" :md="3" :lg="4" :xl="3" class="ml10 font-16 cor337ab7"
                  >affiliate Count:[{{ sumInfo.affiliateCount }}]</span
                >
                <span :xs="4" :sm="3" :md="3" :lg="3" :xl="3" class="ml10 font-16 cor337ab7"
                  >affiliate confirm: [{{ sumInfo.affiliateConfirmCount }}]</span
                >
                <span class="progress-label ml10 font-16 cor337ab7"
                  >affiliate(%):
                  <el-progress
                    :percentage="sumInfo.affiliateConfirmRate"
                    status="success"
                    :text-inside="true"
                    :stroke-width="20"
                  >
                  </el-progress
                ></span>
              </el-form-item>
            </el-col>
            <el-col :xs="11" :sm="11" :md="11" :lg="11" :xl="11" align="right">
              <el-form-item label-width="0" class="mb5">
                <el-button type="primary" :loading="loading.list" @click="getList(1)"
                  >Search</el-button
                >
                <el-button type="warning" @click="insertBatch" :loading="loading.submitBtn"
                  >Create Last Month</el-button
                >
                <el-button type="danger" @click="updateBatch" :loading="loading.submitBtn"
                  >一键confirm</el-button
                >
                <el-button type="info" @click="downloadFie">download</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-card>
      <el-card shadow="hover">
        <el-table
          v-loading="loading.list"
          ref="tableRef"
          style="width: 100%; overflow-x: auto"
          stripe
          border
          :data="list"
          tooltip-effect="dark"
          aria-setsize="mini"
          size="mini"
          class="mb10"
          max-height="800"
          @sort-change="sortChange"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55" align="center" :key="10"></el-table-column>
          <el-table-column
            label="Period"
            prop="financeCycle"
            align="center"
            :key="20"
          ></el-table-column>
          <el-table-column
            label="sourceName"
            prop="sourceName"
            align="center"
            show-overflow-tooltip
            :key="30"
          ></el-table-column>
          <el-table-column
            label="BD Group"
            prop="businessType"
            align="center"
            :key="40"
          ></el-table-column>
          <el-table-column
            label="AffiliateName"
            prop="affiliateName"
            align="center"
            show-overflow-tooltip
            :key="50"
          ></el-table-column>
          <el-table-column
            label="Prod"
            prop="prod"
            align="center"
            show-overflow-tooltip
            :key="60"
          ></el-table-column>
          <el-table-column label="cpi" prop="cpi" align="center" :key="70"></el-table-column>
          <el-table-column
            v-if="filterForm.showOffer"
            label="OfferId"
            prop="offerId"
            align="center"
            :key="80"
          ></el-table-column>
          <el-table-column
            v-if="filterForm.showOffer"
            label="OfferName"
            prop="offerName"
            align="center"
            show-overflow-tooltip
            :key="90"
          ></el-table-column>
          <el-table-column
            label="Pid"
            prop="pid"
            align="center"
            v-if="filterForm.showOffer"
            :key="100"
          ></el-table-column>
          <el-table-column
            label="Tag"
            prop="tag"
            align="center"
            v-if="filterForm.showOffer"
            :key="110"
          ></el-table-column>
          <el-table-column label="status" prop="status" align="center" :key="120">
            <template slot-scope="scope"> {{ formatValue(scope.row.status) }} </template>
          </el-table-column>
          <el-table-column
            label="Events"
            prop="eventCount"
            align="center"
            sortable="custom"
            :key="130"
          ></el-table-column>
          <el-table-column
            label="Installs"
            prop="installs"
            align="center"
            sortable="custom"
            :key="140"
          ></el-table-column>
          <el-table-column
            label="Payout"
            prop="payout"
            align="center"
            sortable="custom"
            :key="150"
          ></el-table-column>
          <el-table-column
            label="Rejects"
            prop="rejects"
            align="center"
            :key="160"
          ></el-table-column>
          <el-table-column
            label="InstallRejectRate"
            prop="installRejectRate"
            align="center"
            :key="170"
          ></el-table-column>
          <el-table-column label="Confirm %" prop="confirmRatio" align="center" :key="180">
            <template slot-scope="scope">
              {{ (scope.row.confirmRatio * 100).toFixed(2) }}%
            </template>
          </el-table-column>
          <el-table-column
            label="Confirm Num"
            prop="confirm"
            align="center"
            :key="190"
          ></el-table-column>
          <el-table-column label="Cover" prop="cover" align="center" :key="200"></el-table-column>
          <el-table-column
            label="Note"
            prop="note"
            align="center"
            show-overflow-tooltip
            :key="210"
          ></el-table-column>
          <el-table-column
            label="Confirm(BD)"
            prop="confirmBd"
            align="center"
            :key="220"
          ></el-table-column>
          <el-table-column
            label="Confirm(MMP)"
            prop="confirmAf"
            align="center"
            :key="230"
          ></el-table-column>
          <el-table-column label="BD Confirm%" prop="confirmAf" align="center" :key="240">
            <template slot-scope="scope">
              <span v-if="scope.row.financeCycle === 'total' && isNaN(scope.row.confirmBd)"></span>
              <span v-else>{{ computeRatio(scope.row) }}%</span>
            </template>
          </el-table-column>
          <el-table-column
            label="Profit Rate"
            prop="profitRate"
            align="center"
            v-if="!filterForm.showOffer"
            :key="250"
          >
            <template slot-scope="scope">
              <template v-if="scope.row.profitRate">
                <span v-if="scope.row.profitRate < 0" style="color: red"
                  >{{ scope.row.profitRate }}%
                </span>
                <span v-else>{{ scope.row.profitRate }}%</span>
              </template>
            </template>
          </el-table-column>
          <el-table-column
            label="Deduction report"
            prop="deductionFileName"
            align="center"
            :key="260"
          >
            <template slot-scope="scope">
              <span @click="openFile(scope.row)" class="cor337ab7">{{
                scope.row.deductionFileName
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="Attachments" prop="confirmAf" align="center" :key="270">
            <template slot-scope="scope">
              <el-button
                v-show="scope.$index !== 0"
                size="mini"
                type="text"
                @click="openAffDialog(scope.row)"
                >view</el-button
              >
            </template>
          </el-table-column>
          <el-table-column
            label="second Confirm %"
            prop="secondConfirmRatio"
            align="center"
            :key="280"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.secondConfirmRatio">
                {{ (scope.row.secondConfirmRatio * 100).toFixed(2) }}%</span
              >
            </template>
          </el-table-column>
          <el-table-column
            label="second Confirm Num"
            prop="secondConfirm"
            align="center"
            :key="290"
          ></el-table-column>

          <el-table-column label="Action" align="center" width="100" fixed="right" :key="300">
            <template slot-scope="scope">
              <el-button
                size="mini"
                type="primary"
                icon="el-icon-edit"
                circle
                @click="openDialog(scope.row)"
                v-show="scope.$index !== 0"
                title="TO Confirm"
              ></el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-dialog :title="dialog.title" :visible.sync="dialog.visible" width="30%">
          <el-form
            :model="affiliateFinance"
            size="small"
            ref="affiliateFinanceForm"
            label-width="auto"
            :rules="rules"
          >
            <el-form-item label="confirm:" prop="confirm">
              <el-input
                v-model="affiliateFinance.confirm"
                disabled
                placeholder="Please enter confirm"
              ></el-input>
            </el-form-item>
            <el-form-item label="confirmRatio:" prop="confirmRatio">
              <el-input
                v-model.number="affiliateFinance.confirmRatio"
                placeholder="Please enter confirm %"
                @blur="computeConfirmNum()"
              >
                <template slot="append">%</template></el-input
              >
            </el-form-item>
            <el-form-item label="note:" prop="note">
              <el-input
                type="textarea"
                v-model="affiliateFinance.note"
                placeholder="Please enter note"
                maxlength="255"
                show-word-limit
              ></el-input>
            </el-form-item>
            <el-form-item label="secondConfirmRatio:" prop="secondConfirmRatio">
              <el-input
                v-model.number="affiliateFinance.secondConfirmRatio"
                placeholder="Please enter second confirm %"
              >
                <template slot="append">%</template></el-input
              >
            </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialog.visible = false">取 消</el-button>
            <el-button type="primary" @click="update()">确 定</el-button>
          </span>
        </el-dialog>
        <el-dialog title="Attachments" :visible.sync="dialog.visibleAttachments" width="30%">
          <el-card shadow="hover">
            <span v-if="financeAttachments == null || financeAttachments.length === 0"
              >No Attachments</span
            >
            <span class="card-div-files" v-for="(item, index) in financeAttachments" :key="index">
              <div>{{ item.reasonKey }}</div>
              <div
                ><a :href="item.fileUrl" target="_blank">{{ item.fileName }}</a></div
              >
            </span>
          </el-card>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialog.visibleAttachments = false">取 消</el-button>
          </span>
        </el-dialog>
        <pagination
          :pageTotal="total"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
          :pageSize="filterForm.pageSize"
          :currentPage="filterForm.page"
        ></pagination>
      </el-card>
    </div>
  </div>
</template>

<script>
  import financeAffiliate from '../controllers/finance/financeAffiliate';
  export default {
    name: 'financeAffiliate',
    ...financeAffiliate,
  };
</script>

<style scoped>
  .table-row {
    height: 50px;
  }

  .button-group {
    display: flex;
    justify-content: space-between;
    align-items: flex-start; /* 将 align-items 改为 flex-start，使按钮顶部对齐 */
    flex-wrap: wrap; /* 添加 flex-wrap 属性，使按钮换行显示 */
    width: 100%; /* 设置容器的宽度为100% */
  }
  .button-group .el-button {
    margin-bottom: 10px; /* 添加按钮之间的间距，可根据需要进行调整 */
  }
  .el-progress {
    width: 35%;
  }
  .progress-label {
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 10px;
  }
  .card-div-files {
    display: block;
    border-radius: 3px;
    margin-top: 5px;
    border: 1px solid rgb(228, 224, 224);
    padding: 5px;
  }
  .card-div-files a {
    color: rgb(119, 173, 243);
  }
</style>
